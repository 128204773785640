<template>
  <b-form id="get-quote" class="p-3 d-none d-lg-block"
          :class="{ 'd-must-show' : shown, 'd-lg-none-important' : hideDesktop}">
    <section class="position-relative index-200">
      <div class="text-right mb-5 d-block d-lg-none">
        <a :href="`tel:${ SITE_DETAILS.phone }`" class="jd-font-medium jd-text-20">
          <i class="fas fa-phone-square"></i>
          {{ SITE_DETAILS.phone }}
        </a>
      </div>
      <h3 class="jd-text-light jd-font-medium mb-3 jd-text-16 text-center jd-text-18__mobile get--quote-free-text">
        GET YOUR FREE QUOTE</h3>
      <template v-if="!quote.quote_id">
        <b-form-group class="mb-4 position-relative">
          <b-input type="text" placeholder="Name"
                   :class="`jd-input ${veeErrors.has('name') ? 'is-invalid' : ''} hover-show-tooltip`"
                   v-validate="'required|max:80'"
                   v-model="quote.name"
                   name="name"></b-input>
          <span v-if="veeErrors.has('name')" class="text-danger jd-text-10 position-absolute">
                                    {{ veeErrors.first('name') }}
                                </span>
        </b-form-group>
        <b-form-group class="mb-4 position-relative">
          <b-input type="email" placeholder="Email"
                   :class="`jd-input ${veeErrors.has('email') ? 'is-invalid' : ''} hover-show-tooltip`"
                   v-validate="'required|email|max:80'"
                   v-model="quote.email"
                   key="email"
                   name="email"></b-input>
          <span v-if="veeErrors.has('email')" class="text-danger jd-text-10 position-absolute">
                                    {{ veeErrors.first('email') }}
                                </span>
        </b-form-group>
        <b-form-group class="mb-4 position-relative">
          <b-input type="text" placeholder="Phone"
                   :class="`jd-input ${veeErrors.has('phone') ? 'is-invalid' : ''} hover-show-tooltip`"
                   v-validate="{required : true, max: 14, min: 14}"
                   v-model="quote.phone"
                   maxlength="14"
                   @input="acceptNumber"
                   name="phone"></b-input>
          <span v-if="veeErrors.has('phone')" class="text-danger jd-text-10 position-absolute">
                                    {{ veeErrors.first('phone') }}
                                </span>
        </b-form-group>
        <b-form-group class="mb-4 position-relative">
          <b-form-select type="text"
                         :options="[{ text: 'Moving Size', value: null, disabled: true}, ...MOVING_SIZES]"
                         :class="`jd-input ${veeErrors.has('moving_size') ? 'is-invalid' : ''} hover-show-tooltip`"
                         v-validate="'required'"
                         v-model="quote.moving_size"
                         name="moving_size"></b-form-select>
          <span v-if="veeErrors.has('moving_size')" class="text-danger jd-text-10 position-absolute">
                                    {{ veeErrors.first('moving_size') }}
                                </span>
        </b-form-group>
      </template>
      <template v-else>
        <b-form-group class="mb-4 position-relative">
          <b-input type="text" placeholder="Move from"
                   :class="`jd-input ${veeErrors.has('origin') ? 'is-invalid' : ''} hover-show-tooltip`"
                   v-validate="'required|max:200'"
                   v-model="quote.origin"
                   data-vv-as="move from"
                   name="origin"></b-input>
          <span v-if="veeErrors.has('origin')" class="text-danger jd-text-10 position-absolute">
                                    {{ veeErrors.first('origin') }}
                                </span>
        </b-form-group>
        <b-form-group class="mb-4 position-relative">
          <b-input type="text"
                   placeholder="Move to"
                   :class="`jd-input ${veeErrors.has('destination') ? 'is-invalid' : ''} hover-show-tooltip`"
                   v-validate="'required|max:200'"
                   data-vv-as="move to"
                   v-model="quote.destination"
                   name="destination"></b-input>
          <span v-if="veeErrors.has('destination')" class="text-danger jd-text-10 position-absolute">
                                    {{ veeErrors.first('destination') }}
                                </span>
        </b-form-group>
        <b-form-group class="mb-4 position-relative">
          <i class="fas fa-calendar-alt position-absolute calendar-icon"></i>
          <datepicker v-validate="'required'" data-vv-name="date" placeholder="Estimated move date" format="MMM dd yyyy"
                      :disabled-dates=disabledFn
                      class="hover-show-tooltip is-invalid"
                      v-model="quote.date"
                      :input-class="` jd-input form-control  pr-0 bg-input w-100 pl-25__input ${veeErrors.has('date') ? 'is-invalid' : ''}`"></datepicker>
          <span v-if="veeErrors.has('date')" class="text-danger jd-text-10 position-absolute">
                            {{ veeErrors.first('date') }}
                        </span>
        </b-form-group>
        <b-form-group class="mb-4 position-relative">
          <b-textarea placeholder="Enter Comment"
                      rows="4"
                      :class="`jd-input ${veeErrors.has('description') ? 'is-invalid' : ''} hover-show-tooltip`"
                      data-vv-as="comment"
                      v-model="quote.description"
                      name="description"></b-textarea>
          <span v-if="veeErrors.has('description')" class="text-danger jd-text-10 position-absolute">
                                    {{ veeErrors.first('description') }}
                                </span>
        </b-form-group>
      </template>

      <b-btn :disabled="loading" variant="primary" block
             class="submit-btn-qoute jd-text-12 jd-text-14__mobile p-1 p-md-0 br-150 text-uppercase" @click="submitForm">
        {{ !quote.quote_id ? 'Let’s go!' : 'Get your quote' }}
      </b-btn>
    </section>
  </b-form>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {RepositoryFactory} from "@/api/RepositoryFactory";
import {mapGetters} from "vuex";

export default {
  components: {Datepicker},
  props: {
    shown: {
      type: Boolean,
      default: false
    },
    hideDesktop: true
  },
  data() {
    return {
      quote: {
        source: this.$route.path,
        quote_id: null,
        name: null,
        email: null,
        phone: null,
        date: null,
        origin: null,
        destination: null,
        moving_size: null,
        description: null,
      },
      loading: false,
      disabledFn: {
        to: this.$moment().subtract(1, "day").toDate()
      }
    }
  },
  created() {
    this.$store.dispatch("GET_MOVING_SIZES");
  },
  watch: {
    shown: function (newVal) {
      if (newVal) {
        document.querySelector('body').classList.add('overflow-hidden');
        document.querySelector('html').classList.add('overflow-hidden');
      } else {
        document.querySelector('body').classList.remove('overflow-hidden');
        document.querySelector('html').classList.remove('overflow-hidden');
      }
    },
    "quote.phone": function (newVal) {
      if (newVal && newVal.length === 4) {
        this.quote.phone += ") ";
      }
    }
  },
  methods: {
    acceptNumber() {
      let x = this.quote.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.quote.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    submitForm() {
      this.$validator.validateAll().then((res) => {
        if (res) {
          this.loading = true;

          if (!this.quote.quote_id) {
            RepositoryFactory.get('site').firstSubmit(this.quote)
                .then((res) => {
                  this.$validator.reset();
                  this.veeErrors.clear();
                  this.quote.quote_id = res.data.id;
                }).catch((err) => {
              if (err.response.status === 400) {
                this.$snotify.error(err.response.data.message);
              } else if (err.response.status === 422) {
                let errors = err.response.data.errors;
                this.$snotify.error("PLease fix bellow errors.");
                Object.keys(errors).map((item) => this.veeErrors.add({
                  field: item,
                  msg: errors[item].join(',')
                }));
              } else {
                this.$snotify.error("Oops, Something went wrong please try again later.");
              }
            }).finally(() => {
              this.loading = false;
            });
          } else {
            RepositoryFactory.get('site').submitForm({
              ...this.quote,
              ...{
                  UtmAdGroup: this.$route.query.adgroup || null,
                  UtmCampaign: this.$route.query.utm_campaign || null,
                  UtmContent: this.$route.query.utm_content || null,
                  UtmCustomTracking: this.$route.query.utm_custom_tracking || null,
                  UtmKeyword: this.$route.query.utm_keyword || null,
                  UtmMedium: this.$route.query.utm_medium || null,
                  UtmSource: this.$route.query.utm_source || null,
              }
            })
                .then((res) => {
                  this.$snotify.success("Your Quote has been submitted, We will contact you soon.");
                  this.$router.push({name: 'thankyou'});
                }).catch((err) => {
              if (err.response.status === 400) {
                this.$snotify.error(err.response.data.message);
              } else if (err.response.status === 422) {
                let errors = err.response.data.errors;
                this.$snotify.error("PLease fix bellow errors.");
                Object.keys(errors).map((item) => this.veeErrors.add({
                  field: item,
                  msg: errors[item].join(',')
                }));
              } else {
                this.$snotify.error("Oops, Something went wrong please try again later.");
              }
            }).finally(() => {
              this.loading = false;
            })
          }
        }
      })
    }
  },
  computed: {
    ...mapGetters(['SITE_DETAILS', 'MOVING_SIZES'])
  }
}
</script>
