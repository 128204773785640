<template>
  <div class="bg-white my-5 py-3">
    <b-container>
      <h1 class="jd-text-36 jd-font-bold text-center jd-text-dark position-relative z-index-5">
        {{ status ? 'Thank you for your order!' : 'Sorry, something wrong with your payment please try again :( ' }}
      </h1>
      <hr class="divider orange"/>

      <b-container class="d-flex justify-content-center mt-5">
        <b-img src="/img/submitTruck.png" class="truck object-fit-cover" height="335px"></b-img>
      </b-container>

      <div class="position-relative z-index-5">
        <div class="text-center">
          <a class="jd-text-dark jd-text-24 jd-font-bold" :href="`tel:${SITE_DETAILS.phone}`">{{ SITE_DETAILS.phone }}</a>
        </div>
        <div class="d-flex justify-content-center">
          <hr class="divider"/>
        </div>
        <p class="jd-text-24 jd-font-bold text-center text-blue text-thankyou">
          If you have any questions or need to make
          <br/> changes  to your order please email
          or call
          <br /> <a href="mailto:info@carefulmovers.com">info@carefulmovers.com</a> / <a :href="`tel:${SITE_DETAILS.phone}`">{{ SITE_DETAILS.phone }}</a><br>
        </p>
        <div class="text-center mt-3">
          <router-link class="btn btn-jd fill jd-font-medium" :to="{ name: 'home'}">Go Back</router-link>
        </div>
      </div>
      <div style="position: relative; z-index: 10">
        <quote-form :shown.sync="openQuote" :hide-desktop="true"></quote-form>
      </div>
    </b-container>
  </div>
</template>

<script>
import QuoteForm from "../components/QuoteForm";
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {QuoteForm},
  data() {
    return {
      openQuote: false
    }
  },
  mounted() {
    this.$root.$emit('hideLoader');
  },
  methods: {
    ...mapActions(['CHANGE_ORDER_STATUS'])
  },
  computed: {
    ...mapGetters(['SITE_DETAILS']),
    status() {
      return this.$route.params.status === 'success';
    }
  },
  created() {
    if (!this.$route.query.order_id) {
      this.$router.push({ name: 'error404' })
      return;
    }

    this.$root.$on('openQuote', () => {
      this.openQuote = !this.openQuote;
    })


    this.CHANGE_ORDER_STATUS({
      order_id: this.$route.query.order_id,
      status: this.$route.params.status
    })
  }
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 996px){
  .truck {
    width: 100% !important;
    height: unset;
  }
}

.text-thankyou {
  @media screen and (max-width: 996px){
    br {
      display: none;
    }
  }
}

.object-fit-contain {
  object-fit: contain;
}

.divider {
  background: #184782;
  height: 4px;
  width: 350px;
  border-radius: 150px;
  &.orange {
    background: #EF4E23;
  }
  @media screen and (max-width: 768px){
    width: 250px;
  }
}
.btn-jd {
  width: 325px;
  height: 31px;
  border-radius: 150px;
  border: solid 1px #e8452a;
  font-size: 12px;
  transition: 0.3s ease;
  cursor: pointer;
  background: #EF4E23;
  color: #fff;
  text-transform: uppercase;

  &:hover {
    background: #fff;
    color: #EF4E23 !important;
  }
}

.z-index-5 {
  z-index: 5;
}

.z-index-4 {
  z-index: 4;
}
</style>
